import React from 'react';
import { Typography, Box } from '@mui/material';

import { Button } from '@material-ui/core';

const Custom404 = () => (
  <Box style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    flexDirection: 'column',
    height: '100vh',
  }}
  >
    <Typography variant="h5" my={4}>404 - Page Not Found</Typography>
    <Button
      href="/"
      variant="contained"
      size="large"
      color="primary"
    >
      Return to homepage
    </Button>
  </Box>
);

export default Custom404;
